import { type Components } from "@mui/material/styles";

import { BORDER_PRIMARY_COLOR } from "../colors";
import { BORDER_RADIUS_MEDIUM } from "../common";

declare module "@mui/material/Card" {
  interface CardOwnProps {
    outlined?: boolean;
  }

  interface CardPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
  }
}

export const muiCardOverrides: Components = {
  MuiCardActionArea: {
    styleOverrides: {
      // by default Mui adds a focus highlight element that is rendered above the component
      focusHighlight: {
        display: "none",
      },
    },
  },
  MuiCard: {
    defaultProps: {
      elevation: 0,
      variant: "tertiary",
    },
    styleOverrides: {
      root: {
        borderRadius: BORDER_RADIUS_MEDIUM,
      },
    },
    variants: [
      {
        props: { outlined: true },
        style: {
          border: `1px solid ${BORDER_PRIMARY_COLOR}`,
        },
      },
    ],
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: "1rem",

        "&:last-child": {
          paddingBottom: "1rem",
        },
      },
    },
  },
};
