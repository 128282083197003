import { type Components } from "@mui/material/styles";

import { PRIMARY_COLOR } from "../colors";

export const muiRatingOverrides: Components = {
  MuiRating: {
    styleOverrides: {
      root: {
        gap: 4,
      },
      readOnly: {
        color: PRIMARY_COLOR,

        "& .MuiRating-iconEmpty": {
          opacity: 1,
        },
      },
      label: {
        color: PRIMARY_COLOR,
      },
      iconEmpty: {
        opacity: 0.2,
      },
      icon: {
        color: PRIMARY_COLOR,

        "& svg": {
          flexShrink: 0,
        },
      },
    },
  },
};
