import { type Components } from "@mui/material/styles";

import {
  BORDER_PRIMARY_COLOR,
  DISABLED_PRIMARY_COLOR,
  DISABLED_SECONDARY_COLOR,
  PRIMARY_COLOR,
  SURFACE_SECONDARY_COLOR,
  TEXT_DISABLED_COLOR,
  TEXT_PRIMARY_COLOR,
  TEXT_TERTIARY_COLOR,
} from "../colors";
import { BORDER_RADIUS_MEDIUM, BORDER_RADIUS_SMALL, ELEVATION_2 } from "../common";
import { BODY_1_FONT_SIZE, BODY_2_FONT_SIZE } from "../typography";

export const INPUT_SIZES = ["small", "medium", "large"] as const;
export type InputSize = (typeof INPUT_SIZES)[number];

export const INPUT_SIZE_SMALL = "2.5rem"; // 40px
export const INPUT_SIZE_MEDIUM = "3rem"; // 48px
export const INPUT_SIZE_LARGE = "4rem"; // 64px

declare module "@mui/material/InputBase" {
  interface InputBasePropsSizeOverrides {
    small: true;
    medium: true;
    large: true;
  }
}

export const muiInputOverrides: Components = {
  MuiOutlinedInput: {
    defaultProps: {
      size: "medium",
      fullWidth: true,
    },
    styleOverrides: {
      root: {
        fontSize: BODY_2_FONT_SIZE,
        color: TEXT_PRIMARY_COLOR,
        backgroundColor: SURFACE_SECONDARY_COLOR,

        input: {
          boxSizing: "border-box",

          "&.MuiInputBase-inputAdornedStart": {
            paddingLeft: "0.5rem", // 8px
          },

          "&::placeholder": {
            "-webkit-text-fill-color": TEXT_TERTIARY_COLOR,
            opacity: 1,
          },

          "&.Mui-disabled::placeholder": {
            "-webkit-text-fill-color": TEXT_DISABLED_COLOR,
          },
        },

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: BORDER_PRIMARY_COLOR,
        },

        "&.Mui-focused": {
          "&:not(.MuiInputBase-multiline)": {
            boxShadow: ELEVATION_2,
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
            borderColor: PRIMARY_COLOR,
          },
        },

        "&.Mui-disabled": {
          color: TEXT_DISABLED_COLOR,
          borderColor: DISABLED_SECONDARY_COLOR,

          "&.MuiInputBase-adornedStart svg": {
            color: DISABLED_PRIMARY_COLOR,
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: DISABLED_SECONDARY_COLOR,
          },
        },

        variants: [
          {
            props: { size: "small" },
            style: {
              borderRadius: BORDER_RADIUS_SMALL,

              input: {
                padding: "0.75rem", // 12px
                height: INPUT_SIZE_SMALL,
              },
            },
          },
          {
            props: { size: "medium" },
            style: {
              borderRadius: BORDER_RADIUS_SMALL,

              input: {
                padding: "1rem", // 16px
                height: INPUT_SIZE_MEDIUM,
              },
            },
          },
          {
            props: { size: "large" },
            style: {
              borderRadius: BORDER_RADIUS_MEDIUM,
              fontSize: BODY_1_FONT_SIZE,

              input: {
                padding: "1.5rem", // 24px
                height: INPUT_SIZE_LARGE,
              },
            },
          },
        ],
      },
    },
  },
};
