import { type Components } from "@mui/material/styles";

import { BORDER_SUBTLE_COLOR } from "../colors";

export const muiDividerOverrides: Components = {
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: BORDER_SUBTLE_COLOR,
      },
    },
  },
};
