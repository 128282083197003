import { type Components } from "@mui/material/styles";

export const muiDrawerOverrides: Components = {
  MuiDrawer: {
    styleOverrides: {
      paperAnchorBottom: {
        maxWidth: "var(--page-max-width)",
        maxHeight: "95%",
        margin: "0 auto",
      },
    },
  },
};
