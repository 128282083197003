import { type Components } from "@mui/material/styles";

import {
  BACKGROUND_PRIMARY_COLOR,
  BORDER_PRIMARY_COLOR,
  DISABLED_PRIMARY_COLOR,
  DISABLED_SECONDARY_COLOR,
  TEXT_PRIMARY_COLOR,
} from "../colors";
import { BORDER_WIDTH_THIN, SPACINGS } from "../common";

export const PILL_HEIGHT_SMALL = "1.75rem"; // 28px
export const PILL_HEIGHT_MEDIUM = "2rem"; // 32px

export const muiChipOverrides: Components = {
  MuiChip: {
    styleOverrides: {
      root: {
        "& .MuiChip-icon, & .MuiChip-label, & .MuiTypography-root": {
          color: TEXT_PRIMARY_COLOR,
          margin: 0,
          padding: 0,
        },

        "& > *:last-child:not(:only-child):not(:empty)": {
          marginLeft: SPACINGS[2],
        },

        "&.Mui-disabled": {
          opacity: 1,

          "& .MuiChip-icon, & .MuiChip-label, & .MuiTypography-root": {
            color: DISABLED_PRIMARY_COLOR,
          },
        },

        variants: [
          {
            props: { variant: "filled" },
            style: {
              backgroundColor: BACKGROUND_PRIMARY_COLOR,

              "&.Mui-disabled": {
                backgroundColor: DISABLED_SECONDARY_COLOR,
              },
            },
          },
          {
            props: { variant: "outlined" },
            style: {
              border: `${BORDER_WIDTH_THIN} solid ${BORDER_PRIMARY_COLOR}`,

              "&.Mui-disabled": {
                borderColor: DISABLED_SECONDARY_COLOR,
              },
            },
          },
        ],
      },

      sizeSmall: {
        height: PILL_HEIGHT_SMALL,
        paddingLeft: "12px",
        paddingRight: "12px",
      },

      sizeMedium: {
        height: PILL_HEIGHT_MEDIUM,
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
  },
};
