import { type Components } from "@mui/material/styles";

import {
  BACKGROUND_PRIMARY_COLOR,
  BACKGROUND_SECONDARY_COLOR,
  BACKGROUND_TERTIARY_COLOR,
} from "../colors";
import { ELEVATION_1, ELEVATION_2, ELEVATION_3 } from "../common";

export const PAPER_VARIANTS = ["primary", "secondary", "tertiary"] as const;
export type PaperVariant = (typeof PAPER_VARIANTS)[number];

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
  }
}

export const muiPaperOverrides: Components = {
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    variants: [
      {
        props: { variant: "primary" },
        style: {
          backgroundColor: BACKGROUND_PRIMARY_COLOR,
        },
      },
      {
        props: { variant: "secondary" },
        style: {
          backgroundColor: BACKGROUND_SECONDARY_COLOR,
        },
      },
      {
        props: { variant: "tertiary" },
        style: {
          backgroundColor: BACKGROUND_TERTIARY_COLOR,
        },
      },
      {
        props: { elevation: 0 },
        style: {
          boxShadow: "none",
        },
      },
      {
        props: { elevation: 1 },
        style: {
          boxShadow: ELEVATION_1,
        },
      },
      {
        props: { elevation: 2 },
        style: {
          boxShadow: ELEVATION_2,
        },
      },
      {
        props: { elevation: 3 },
        style: {
          boxShadow: ELEVATION_3,
        },
      },
    ],
  },
};
