import { type Components } from "@mui/material/styles";

import { getSafeAreaPaddings } from "../../utils/getSafeAreaPaddings";

export const muiDialogOverrides: Components = {
  MuiDialog: {
    styleOverrides: {
      paperFullScreen: {
        ...getSafeAreaPaddings({ top: true, bottom: true, left: true, right: true }),
        maxWidth: "var(--page-max-width)",
      },
    },
  },
};
