import { type Components } from "@mui/material/styles";

import { BORDER_PRIMARY_COLOR, TEXT_INVERT_PRIMARY_COLOR } from "../colors";
import { BORDER_RADIUS_LARGE, BORDER_RADIUS_XLARGE } from "../common";

export const muiTabsOverrides: Components = {
  MuiTabs: {
    styleOverrides: {
      root: {
        width: "fit-content",
        borderRadius: BORDER_RADIUS_LARGE,
        border: `1px solid ${BORDER_PRIMARY_COLOR}`,
        padding: "0.25rem",
        height: "3rem",
      },
      indicator: {
        height: "100%",
        borderRadius: BORDER_RADIUS_XLARGE,
        // to show it below button content
        zIndex: 1,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        // to show it above the tab indicator
        zIndex: 2,
        textTransform: "none",
        padding: "0.75rem 1rem",
        flexDirection: "row",
        lineHeight: "1rem",
        minHeight: "2.5rem",
        whiteSpace: "nowrap",

        "&.Mui-selected": {
          color: TEXT_INVERT_PRIMARY_COLOR,
        },

        "&.MuiButtonBase-root": {
          transition: "color 0.3s ease-in-out",
        },
      },
    },
  },
};
