import { type Components } from "@mui/material/styles";

import {
  BACKGROUND_SECONDARY_COLOR,
  BACKGROUND_TERTIARY_COLOR,
  BORDER_PRIMARY_COLOR,
  BORDER_PRIMARY_INVERT_COLOR,
  DISABLED_SECONDARY_COLOR,
  DISABLED_TERTIARY_COLOR,
  ICON_DISABLED_COLOR,
  ICON_INVERT_LIGHT_COLOR,
  ICON_PRIMARY_COLOR,
  PRIMARY_COLOR,
  PRIMARY_DARK_COLOR,
  TEXT_INVERT_PRIMARY_COLOR,
} from "../colors";
import { ELEVATION_2 } from "../common";

export const ICON_BUTTON_SIZE_EXTRA_SMALL = "1.25rem"; // 20px
export const ICON_BUTTON_SIZE_SMALL = "2.5rem"; // 40px
export const ICON_BUTTON_SIZE_MEDIUM = "3rem"; // 48px
export const ICON_BUTTON_SIZE_LARGE = "4rem"; // 64px

export const ICON_BUTTON_SIZES = ["xSmall", "small", "medium", "large"] as const;
export type IconButtonSize = (typeof ICON_BUTTON_SIZES)[number];

/**
 * These variants map to MuiIconButton's color prop:
 * - "contained" -> "primary"
 * - "outlined" -> "secondary"
 */
export const ICON_BUTTON_VARIANTS = ["contained", "outlined"] as const;
export type IconButtonVariant = (typeof ICON_BUTTON_VARIANTS)[number];

declare module "@mui/material/IconButton" {
  interface IconButtonPropsSizeOverrides {
    xSmall: true;
  }

  interface IconButtonOwnProps {
    /**
     * Inverts the button colors.
     */
    invert?: boolean;
  }
}

/**
 * Use IconButton from src/appV2/ShiftDiscovery/components/IconButton.tsx
 * instead of MUI IconButton.
 */
export const muiIconButtonOverrides: Components = {
  MuiIconButton: {
    styleOverrides: {
      root: {
        // MUI's IconButton has default padding for the icon,
        // but we are using our custom CBHIcon component,
        // so we need to clean that up.
        padding: 0,

        variants: [
          {
            props: { size: "xSmall" },
            style: {
              width: ICON_BUTTON_SIZE_EXTRA_SMALL,
              height: ICON_BUTTON_SIZE_EXTRA_SMALL,

              // The icon sizes in the designs don't match CbhIcon sizes,
              // so we need to override them here.
              "& svg": {
                width: "0.875rem", // 14px
                height: "0.875rem", // 14px
              },
            },
          },
          {
            props: { size: "small" },
            style: {
              width: ICON_BUTTON_SIZE_SMALL,
              height: ICON_BUTTON_SIZE_SMALL,

              "& svg": {
                width: "1.25rem", // 20px
                height: "1.25rem", // 20px
              },
            },
          },
          {
            props: { size: "medium" },
            style: {
              width: ICON_BUTTON_SIZE_MEDIUM,
              height: ICON_BUTTON_SIZE_MEDIUM,

              // These sizes match CbhIcon size ICON_SIZE_MEDIUM,
              // but still leaving them here to centralize all IconButton's icon sizes.
              "& svg": {
                width: "1.5rem", // 24px
                height: "1.5rem", // 24px
              },
            },
          },
          {
            props: { size: "large" },
            style: {
              width: ICON_BUTTON_SIZE_LARGE,
              height: ICON_BUTTON_SIZE_LARGE,

              "& svg": {
                width: "1.5rem", // 24px
                height: "1.5rem", // 24px
              },
            },
          },
        ],
      },

      // Used for "contained" variant
      colorPrimary: {
        color: TEXT_INVERT_PRIMARY_COLOR,
        backgroundColor: PRIMARY_COLOR,

        "@media (hover: hover)": {
          "&:hover, &:focus": {
            backgroundColor: PRIMARY_DARK_COLOR,
          },
        },

        "@media (hover: none)": {
          // Override default hover styles on mobile.
          // Even though mobile doesn't support hover, it still shows
          // hover styles when pressed.
          "&:hover": {
            backgroundColor: PRIMARY_COLOR,
          },

          "&:active": {
            backgroundColor: PRIMARY_DARK_COLOR,
          },
        },

        "&.Mui-disabled": {
          color: ICON_DISABLED_COLOR,
          backgroundColor: DISABLED_TERTIARY_COLOR,
        },

        variants: [
          {
            props: { invert: true },
            style: {
              color: ICON_PRIMARY_COLOR,
              backgroundColor: BACKGROUND_TERTIARY_COLOR,

              "@media (hover: hover)": {
                "&:hover, &:focus": {
                  backgroundColor: BACKGROUND_SECONDARY_COLOR,
                },
              },

              "@media (hover: none)": {
                "&:hover": {
                  backgroundColor: BACKGROUND_TERTIARY_COLOR,
                },

                "&:active": {
                  backgroundColor: BACKGROUND_SECONDARY_COLOR,
                },
              },
            },
          },
        ],
      },

      // Used for "outlined" variant
      colorSecondary: {
        color: ICON_PRIMARY_COLOR,
        border: `1px solid ${BORDER_PRIMARY_COLOR}`,
        backgroundColor: BACKGROUND_TERTIARY_COLOR,
        boxShadow: ELEVATION_2,

        "@media (hover: hover)": {
          "&:hover, &:focus": {
            border: `1px solid ${PRIMARY_COLOR}`,
            // Override default hover background color.
            backgroundColor: BACKGROUND_TERTIARY_COLOR,
          },
        },

        "@media (hover: none)": {
          // Override default hover styles on mobile to look like default styles.
          // Even though mobile doesn't support hover, it still shows
          // hover styles when pressed.
          "&:hover": {
            border: `1px solid ${BORDER_PRIMARY_COLOR}`,
            backgroundColor: BACKGROUND_TERTIARY_COLOR,
          },

          "&:active": {
            border: `1px solid ${PRIMARY_COLOR}`,
            backgroundColor: BACKGROUND_TERTIARY_COLOR,
          },
        },

        "&.Mui-disabled": {
          color: ICON_DISABLED_COLOR,
          border: `1px solid ${DISABLED_SECONDARY_COLOR}`,
          backgroundColor: BACKGROUND_TERTIARY_COLOR,
        },

        variants: [
          {
            props: { invert: true },
            style: {
              color: ICON_INVERT_LIGHT_COLOR,
              border: `1px solid ${BORDER_PRIMARY_INVERT_COLOR}`,
              backgroundColor: "transparent",
              boxShadow: "none",

              "&.Mui-disabled": {
                color: ICON_DISABLED_COLOR,
                border: `1px solid ${BORDER_PRIMARY_INVERT_COLOR}`,
                backgroundColor: "transparent",
              },

              "@media (hover: hover)": {
                "&:hover, &:focus": {
                  border: `1px solid ${ICON_INVERT_LIGHT_COLOR}`,
                  // Override default hover background color.
                  backgroundColor: "transparent",
                },
              },

              "@media (hover: none)": {
                // Override default hover styles on mobile to look like default styles.
                // Even though mobile doesn't support hover, it still shows
                // hover styles when pressed.
                "&:hover": {
                  border: `1px solid ${BORDER_PRIMARY_INVERT_COLOR}`,
                  backgroundColor: "transparent",
                },

                "&:active": {
                  border: `1px solid ${ICON_INVERT_LIGHT_COLOR}`,
                },
              },
            },
          },
        ],
      },
    },
  },
};
