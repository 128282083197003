import { type Components } from "@mui/material/styles";

import { BORDER_PRIMARY_COLOR, TEXT_PRIMARY_COLOR, TEXT_TERTIARY_COLOR } from "../colors";

declare module "@mui/material/Slider" {
  interface SliderOwnProps {
    variant?: "circular" | "rectangular";
    thumb?: boolean;
  }
}

export const muiSliderOverrides: Components = {
  MuiSlider: {
    defaultProps: {
      variant: "circular",
      thumb: false,
    },
    styleOverrides: {
      root: {
        // TBD, but we don't have guidelines on larger slider in design spec
        height: "0.5rem",
        padding: "0.25rem",

        "& .MuiSlider-rail": {
          height: "0.5rem",
        },

        // MUI has a media query for touch devices, but it breaks the layout on mobile
        "@media (pointer: coarse)": {
          padding: "0.25rem",
        },

        variants: [
          {
            props: { variant: "rectangular" },
            style: {
              borderRadius: 0,
            },
          },
        ],
      },
      rail: {
        color: BORDER_PRIMARY_COLOR,
      },
      track: {
        border: "none",
      },
      mark: {
        display: "none",
      },
      markLabel: {
        marginTop: "1rem",
        color: TEXT_TERTIARY_COLOR,
        fontWeight: 500,

        "&.MuiSlider-markLabelActive": {
          color: TEXT_PRIMARY_COLOR,
        },
        // Align first mark label with the slider.
        // By default, transform is translateX(-50%).
        "&:nth-child(1 of .MuiSlider-markLabel)": {
          transform: "translateX(0)",
        },
        // Align last mark label with the slider.
        "&:nth-last-child(1 of .MuiSlider-markLabel)": {
          transform: "translateX(-100%)",
        },
      },
      thumb: {
        variants: [
          {
            props: { thumb: false },
            style: { display: "none" },
          },
          {
            props: { thumb: true },
            style: {
              "&:hover, &.Mui-focusVisible": {
                boxShadow: "0px 0px 0px 1px",
              },
              "&.Mui-active": {
                boxShadow: "0px 0px 0px 2px",
              },
            },
          },
          {
            props: { variant: "rectangular", thumb: true },
            style: {
              width: "2px",
              height: "calc(100% + 4px)",
              borderRadius: 0,
            },
          },
        ],
      },
      sizeSmall: {
        height: "0.25rem",

        "& .MuiSlider-rail": {
          height: "0.25rem",
        },
      },
    },
  },
};
